import React from 'react'
import Layout from '../components/layout'

const Terms = () => <Layout>
  <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
    <div className="max-w-max-content lg:max-w-7xl mx-auto">
      <div className="relative z-10 mb-8 md:mb-2 md:px-6">
        <div className="text-base max-w-prose lg:max-w-none">
          <p className="leading-6 text-blue-700 font-semibold tracking-wide uppercase">Privacy Policy</p>
        </div>
      </div>
      <div className="relative">
         <div className="relative md:bg-white md:p-6">
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 mb-8">
            <div className="prose prose-lg text-gray-500 mb-6 lg:max-w-none lg:mb-0">
              <p>
                Yeah I collect your data. I have to! How else are you going to get access to the
                classes? I only use it with your consent and when I’m done with it I’ll keep it safe,
                don’t worry.
              </p><p>
              Below you can read how I collect your data, how I use it, why I use it, and how I share
              it when you visit or make a purchase from my site waxcarvers.com
            </p>
              <h3>
                Consent
              </h3>
              <p>
                We all want our consent to de explicit these days, but on the internet it’s difficult!
                So here is how you consent:
              </p><p>
                By using this website, you consent to this privacy policy.
                When you make a purchase, you are providing me with your information out of your own
                free will and I take that as consent. Your information only gets used for this
                specific action.
            </p><p>
                If you sign up for my email list, I specifically ask you for your consent and give
                you the option to get out any time you want.
              </p>
              <h3>
                Personal information
              </h3>
              <p>
                <span className="font-bold">How?</span> I ask you for it!
              </p><p>

              When you make a purchase, I ask for all your details: name, email address and payment
              details!
            </p><p>
              <span className="font-bold">Why?</span> I need all this information just to process the order!
            </p><p>
              Payment details: because I like to get paid.<br />
              Email address: so I can give you access to the class.<br />
              Name: goes together with the payment details.
            </p><p>
              If you decide to sign up to my email list I ask for
              your name and email address. This way I can send you emails about everything that is
              happening with Wax Carvers. If you decide that you don't want to receive
              emails anymore, you can just unsubscribe.
            </p><p>
              I keep your details for my personal
              records, but I won't use them. If you don't want me to keep your
              information, I'll delete it once you tell me to do so.
            </p><p>
              Your information is always stored on a secure server and transferred over a secure
              connection.
            </p>
            </div>
            <div className="prose prose-lg text-gray-500">
              <h3>
                Device information
              </h3><p>
              <span className="font-bold">How?</span> this mostly gets done automatically!
            </p><p>
              <span className="font-bold">Why?</span> By tracking this information I can improve the website experience!

            </p>
              <p>
                We know all about the cookies, you get a warning every time you go on a new website.
                If you don’t like them, turn them off. It might affect the way the
                website works though.
              </p><p>
              Google Analytics does most of the work for me here. It tells me how you use the
              website. If it takes you 10 clicks to get to the class you want, it’s about 8 clicks too
              many. I want my website to run smoothly. Collecting this
              information and analyzing how you use the website helps me do that.
            </p><h3>
              Sharing your information
            </h3><p>
              <span className="font-bold">Why?</span> It’s the only way to make the business run smoothly.
            </p><p>
              When I take a payment, Stripe needs to know your details for the payment to go through. When
              I send you an email, Convertkit needs to know your details because how else is the
              email going to reach you?
            </p><p>
              All third parties have their own privacy policies, you can read how they handle your
              information in the following places:
            </p><p className="leading-5 whitespace-pre-wrap">
              <div className="mb-5">Google: <a className="text-indigo-500" href="https://www.google.com/intl/en/policies/privacy/"
                              target="_blank" rel="noreferrer noopener">https://www.google.com/intl/en/policies/privacy/.</a></div>
              <div className="mb-5">Convertkit: <a className="text-indigo-500" href="https://convertkit.com/privacy" target="_blank"
                                                   rel="noreferrer noopener">https://convertkit.com/privacy</a></div>
                <div className="mb-5">Stripe: <a className="text-indigo-500" href="https://stripe.com/en-at/privacy" target="_blank"
                                                 rel="noreferrer noopener">https://stripe.com/en-at/privacy</a></div>

              I'll also share your information if I am required to do so by the law.
            </p><h3>
              Changes
            </h3><p>
              I'll update this privacy policy when there is a change in how and why I collect your
              data. You can check back here to see if there is anything new since the last time you
              read this.
            </p><p>
              Last update: January 4th, 2022
            </p><h3>
              Questions
            </h3><p>
              Anything unclear? Please send me an email: <a className="text-indigo-500"
                                                            href="mailto:info@waxcarvers.com">info@waxcarvers.com</a>
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Layout>

export default Terms
